import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { stripeApi } from './app/stripe/StripeApi';
import Login from './app/auth/Login';
import SignUp from './app/auth/SignUp';
import ForgotPassword from './app/auth/ForgotPassword';
import Error from './app/auth/Error';
import { firebase } from './firebase';
import Subscribe from './app/stripe/Subscribe';
import Pay from './app/stripe/Pay';
import HomeEdit from './app/home/HomeEdit';
import Handle from './app/extras/Handle';
import ResetPassword from './app/auth/ResetPassword';
import { PageLoading } from './app/components';
import { CustomerActions, LocationActions, EventsAction } from './app/actions';
import customerAction from './app/actions/Customer';
import newUserAction from './app/actions/NewUser';
import locationAction from './app/actions/Location';
import Logout from './app/auth/Logout';
import LocationInfo from './app/extras/LocationInfo';
import Message from './app/components/Message';
import CreateEvent from './app/events/Create';
import eventAction from './app/actions/Event';
import EventDetails from './app/events/Details';
import SubscriptionPage from './app/home/Subscription';
import LocationPage from './app/home/Location';
import createStore from './configStore';
import UploadTrivia from './app/events/trivia/Upload';
import UploadMatch from './app/events/match/Upload';
import Maps from './app/home/Maps';

const { store, persistor } = createStore();
// store.subscribe(() => console.log(store.getState().customers));
let installEvent;
window.addEventListener('beforeinstallprompt', event => {
  event.preventDefault();
  if (
    !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return;
  }
  installEvent = event;
  const btn = document.querySelector('#addToHome');
  btn.style.display = 'flex';
  if (!!btn.getAttribute('data-listen') || !installEvent) {
    return;
  }
  btn.setAttribute('data-listen', 1);
  const addBtn = document.querySelector('#btnAdd');
  addBtn.onclick = function() {
    installEvent.prompt();
    installEvent.userChoice.then(choiceResult => {
      if (choiceResult.outcome === 'accepted') {
        installEvent = null;
        btn.style.display = 'none';
      }
    });
  };
});

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true, // initial state before firebase checks auth
      authUser: 'default',
      stripe: null
    };
  }

  componentDidMount() {
    if (window.Stripe) {
      this.setState({
        stripe: window.Stripe(stripeApi.publicToken)
      });
    } else {
      document.querySelector('#stripejs').addEventListener('load', () =>
        this.setState({
          stripe: window.Stripe(stripeApi.publicToken)
        })
      );
    }
    this.removeListener = firebase.auth.onAuthStateChanged(async authUser => {
      if (authUser) {
        this.checkIfNewUser(authUser);
        const userEmail = authUser.email;
        /**
         * This is our entry point, so we will dispatch these here
         * to assure all pages can read the state data without
         * having to call these
         */
        store.dispatch(customerAction(CustomerActions.Get, { userEmail }));
        store.dispatch(locationAction(LocationActions.Get, { userEmail }));
        store.dispatch(eventAction(EventsAction.Get, { userEmail }));

        setTimeout(() => {
          // make it asynchronous to let things happen
          localStorage.setItem('authUser', userEmail);
          this.setState({ authUser, isLoading: false });
        }, 0);
      } else {
        this.setState({ authUser: null, isLoading: false });
        localStorage.clear();
      }
    });

    this.checkIfNewUser = authUser => {
      const creationDate = authUser.metadata.creationTime;
      const signInTime = authUser.metadata.lastSignInTime;
      // its a new user if creationDate === signInTime
      store.dispatch(newUserAction(creationDate === signInTime));
    };
  }

  componentWillUnmount() {
    this.removeListener();
  }

  renderPublicRoutes = () => (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route path="/signup" component={SignUp} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset" component={ResetPassword} />
      <Route path="/handle" component={Handle} />
      <Route path="/event/:id" component={EventDetails} />
      <Route path="/location/maps" component={Maps} />
      <Route
        path="/logout"
        component={props => {
          // Fixes: logout page stuck with not found page
          props.history.push('/');
          return null;
        }}
      />
      <Route
        path="/:locationId/:uid/:lat/:lng/:view"
        component={LocationInfo}
      />
      <Route path="*" component={Error} />
    </Switch>
  );

  renderPrivateRoutes = stripe => (
    <PersistGate loading={null} persistor={persistor}>
      <StripeProvider stripe={stripe}>
        <Elements>
          <Switch>
            <Route exact path="/" component={LocationPage} />
            <Route path="/home" component={LocationPage} />
            <Route path="/edit" component={HomeEdit} />
            <Route path="/subscribe" component={Subscribe} />
            <Route path="/pay/:subscription" component={Pay} />
            <Route path="/logout" component={Logout} />
            <Route path="/handle" component={Handle} />
            <Route path="/trivia/upload" component={UploadTrivia} />
            <Route path="/match/upload" component={UploadMatch} />
            <Route path="/subscriptions" component={SubscriptionPage} />
            <Route path="/locations" component={LocationPage} />
            <Route path="/event/create" component={CreateEvent} />
            <Route path="/event/:id" exact component={EventDetails} />
            <Route path="/event/:id/edit" component={CreateEvent} />
            <Route path="/location/maps" component={Maps} />
            <Route
              path="/:locationId/:uid/:lat/:lng/:view"
              component={LocationInfo}
            />
            <Route path="*" component={Error} />
          </Switch>
        </Elements>
      </StripeProvider>
    </PersistGate>
  );

  render() {
    const { stripe, authUser, isLoading } = this.state;

    if (isLoading) {
      return <PageLoading description="Making things ready..." />;
    }

    return (
      <Provider store={store}>
        <div>
          {authUser
            ? this.renderPrivateRoutes(stripe)
            : this.renderPublicRoutes()}
          <Message />
        </div>
      </Provider>
    );
  }
}

export default App;
